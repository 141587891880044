import Vue from 'vue'
import VShowSlide from 'v-show-slide'
import VueYoutube from 'vue-youtube'
import TroyEffect from './components/TroyEffect'
import HeroTeaser from './components/HeroTeaser'
import CardTeaser from './components/CardTeaser'
import Carousel from './components/Carousel'
import PressSlider from './components/PressSlider'
import VideoPlayer from './components/VideoPlayer'
import scrollTo from './scroll-to'
import './modernizr'

import './sass/troy.scss';

Vue.use(VueYoutube)
Vue.use(VShowSlide)
Vue.config.productionTip = false

const root = new Vue({
    el: '#content',
    components: {
        HeroTeaser,
        TroyEffect,
        CardTeaser,
        Carousel,
        PressSlider,
        VideoPlayer
    }
});

//new Vue(TroyEffect).$mount('.container.troy-effect')

/**
 * Header Animation
 */
$(function() {
    const $header = $('#primary-navbar'),
          delta = 5;
    let didScroll = false,
        lastScrollTop = 0,
        headerHeight = $header.height();

    const viewport = $( window ).height();
    const scrollHandler = () => {
        didScroll = true;
        let top = $(window).scrollTop();
        if (top > 80) {
            $header.addClass('scrolled primary');
        } else {
            $header.removeClass('scrolled primary');
        }

        if (Math.abs(lastScrollTop - top) > delta) {
            if (top > lastScrollTop && top > headerHeight) {
                $header.addClass('nav-up')
            } else {
                if (top + viewport < $(document).height()) {
                    $header.removeClass('nav-up')
                }
            }
        }

        lastScrollTop = top;
    };

    scrollHandler();
    //$(window).on('scroll', () => didScroll = true);
    $(window).on('scroll', scrollHandler);

    setInterval(() => {
        if (didScroll) { root.$emit('scroll', $(window).scrollTop(), viewport); didScroll = false;}
    }, 250);

    /**
     * Scroll Animation
     */
    $('a.scroll').on('click', (e) => {
        e.preventDefault();
        scrollTo(e.target.getAttribute('data-target'), 1000);
    })
});
