<script>
import SVG from 'svg.js'
export default {
    replace: false,
    data() {
        return {
            svg: null,
            line: null,
            visible: false
        }
    },
    mounted() {
        this.svg = SVG(this.$refs.container);
        this.draw();
        $(window).resize(this.checkRedraw);
        this.$root.$on('scroll', (scrollTop) => {
            if (scrollTop > this.$el.getBoundingClientRect().top) {
                this.visible = true;
            }
        })
    },
    methods: {
        getCoordinates() {
            let container = this.$refs.container.getBoundingClientRect(),
                text1 = this.$refs.text1.getBoundingClientRect(),
                text2 = this.$refs.text2.getBoundingClientRect(),
                intro = this.$refs.intro.getBoundingClientRect();

            return [
                [intro.left - container.left, intro.top - container.top],
                [text1.left - container.left, text1.top - container.top],
                [text2.left - container.left, text2.top - container.top],
                [container.width / 2, container.height]
            ]
        },
        draw() {
            this.line = this.svg.polyline(this.getCoordinates())
                .stroke({ width: 3, color: '#5C18B1', opacity: 0.6 })
                //.attr({'stroke-dasharray': 800, 'stroke-dashoffset': 800})
                .fill('none');
        },
        redraw() {
            this.line.plot(this.getCoordinates());
        },
        checkRedraw() {
            if (JSON.stringify(this.line.array().value) !== JSON.stringify(this.getCoordinates())) {
                this.redraw();
            }
        }
    }
}
</script>
<style lang="scss">

    /*span.connector {
        width: 5px;
        height: 5px;
        background: red;
        display: inline-block;
    }*/

    .svg-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;

        svg polyline {
            stroke-dasharray: 800;
            stroke-dashoffset: 800;
        }
    }


</style>
