import { SlideYDownTransition, FadeTransition } from 'vue2-transitions'

export default {
    props: {
        modal: {type: Boolean, default: false}
    },
    components: {
        SlideYDownTransition,
        FadeTransition
    },
    data() {
        return {
            playVideo: false,
            paused: false,
            modalInstance: null,
            playerVars: {
                origin: window.location.origin,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
                info: 0
            }
        }
    },
    computed: {
        player() {
            return this.$refs.youtube.player;
        }
    },
    mounted() {

    },
    methods: {
        play() {
            if (this.modal) {
                this.modalInstance = $(this.$refs.modal).modal({
                    //backdrop: 'static',
                    show: true
                })
                    .on('hide.bs.modal', () => this.player.pauseVideo())
                    .on('shown.bs.modal', () => {
                        this.$refs.youtube.resizeProportionally();
                        this.player.playVideo();
                    })
                ;
            } else {
                this.playVideo = true;
                this.paused = false;
                //this.$refs.youtube.resizeProportionally();
                this.player.playVideo();

            }
        },
        close() {
            this.player.pauseVideo();
            this.playVideo = false;
        }
    }
}
