import 'slick-carousel';

export default {
    props: ['slides'],
    mounted() {
        $(this.$refs.container).slick({
            dots: true,
            infinite: true,
            slidesToShow: 3,
            arrows: false,
            responsive: [{
                breakpoint: 736,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }

            ]
        });
    }
}
