import '../modernizr';
export default {
    data() {
        return {
            reactive: true,
            open: false,
        }
    },
    created() {
        this.reactive = true;
    },
    computed: {
        minHeight() {
            if (this.open) {
                return ($(this.$refs.content).outerHeight() + 60) + 'px';
            } else {
                return '300px';
            }
        },
        can3d() {
            return Modernizr.preserve3d;
        }
    }
}
