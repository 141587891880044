<template>
    <div class="carousel-inner">
    <div class="row">
        <div class="carousel-container col">
            <carousel3d ref="carousel" height="375" border="0" :clickable="true" :loop="true" @after-slide-change="update">
                <slide v-for="(slide, i) in slides" :index="i" :key="i">
                    <img :src="slide.image" />
                </slide>
            </carousel3d>
        </div>
        <div class="text col">
            <h3 v-html="headline"></h3>
            <p v-html="text"></p>
        </div>
    </div>
    <ul class="pagination">
        <li v-for="(slide, i) in slides" :key="i" :class="{'active': i === current}">
            <a href="#" @click.prevent="goto(i)"></a>
        </li>
    </ul>
    </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
    props: ['slides'],
    components: {
        Carousel3d,
        Slide
    },
    data() {
        return {
            current: 0
        }
    },
    computed: {
        text()     { return this.slides[this.current].text; },
        headline() { return this.slides[this.current].headline; }
    },
    methods: {
        update(i) {
            this.current = i;
        },
        goto(i) {
            this.$refs.carousel.goSlide(i);
        }
    }
}
</script>
