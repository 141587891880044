<template>
    <div class="card">
        <img v-if="image" :src="image" class="card-img-top" :title="headline" :alt="headline" />

        <div class="card-body">
            <h3 class="card-title" v-html="headline"></h3>

            <div v-html="text"></div>
            <div v-show-slide="open" v-html="textMore"></div>

            <a class="btn btn-troy-link" @click="open = !open" :class="{'open': open}">
                {{lang ==="de-DE"
                    ?  !open ? 'Weiterlesen' : 'Schließen' 
                    :  !open ? 'read more' : 'close' 
                }}
                <i class="icon-arrow-right"></i>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: ['image', 'text', 'textMore', 'headline'],
    data() {
        return {
            open: false,
            lang: document.documentElement.lang
        }
    }
}
</script>
